<template>
  <div class="about">
    <div class="aboutTop">
      <img v-lazy="topBg" alt="" srcset="">
      <div class="text">
        <p>千述信息科技（厦门）有限公司</p>
      </div>
    </div>
    <div class="about-center">
      <div class="about-center-top">
        <p>公司简介</p>
      </div>
      <div class="about-center-center" v-lazy:backgroundImage="img">
        <!-- <img v-lazy="img" alt=""> -->
        <div class="about-center-center-top">
          <p>关于我们</p>
        </div>
        <div class="about-center-center-line">

        </div>
        <div class="about-center-center-bottom">
          <p>千述信息科技(厦门)有限公司是一家为各行各业提供解决方案的公司，利用移动互联、大数据、人工智能和物联网等先进技术，为众多企业提供系统开发、技术咨询及大数据运营等服务。</p>
          <p>公司组建了各环节的专项团队，从产品开发落地到上线运营到售后服务，各团队分工明确，执行高效，致力为客户提供最全面舒心的服务。除解决方案外，公司自主研发设计了商城平台及区块链系统等产品，发挥独创的多场景、定制化、全方位、精准触达的核心优势，赋能企业营销快速裂变。
          </p>
        </div>
      </div>
      <div class="about-center-bottom">
        <div v-for="(item,index) in centerList" :key="index">
          <img v-lazy="item.img" alt="">
          <p>{{ item.title }}</p>
          <p>{{ item.span }}</p>
        </div>
        <!-- <img v-lazy="center1" alt="">
        <img v-lazy="center2" alt="">
        <img v-lazy="center3" alt=""> -->
      </div>
    </div>

    <div class="about-culture">
      <div class="about-culture-left">
        <img :src="culture" alt="">
      </div>
      <div class="about-culture-right">
        <div class="title">企业文化</div>
        <p>千述科技以打造最具指向性的定制服务</p>
        <p>为宗旨，以成就客户、服务至上、创新</p>
        <p>合作、激情奋斗、诚信立业的价值观为</p>
        <p>基石，以专业技术帮助客户实现品牌落</p>
        <p>地，努力奋斗成为最优秀的互联网定制</p>
        <p>品牌。</p>
      </div>
    </div>

    <div class="about-bottom">
      <div class="about-bottom-top">
        <p>团队构成</p>
      </div>
      <div class="about-bottom-bottom">
        <div v-for="(item,index) in teamList" :key="index" >
          <img v-lazy="item.img" alt="">
          <p>{{ item.title }}</p>
          <p>{{ item.span.slice(0,9) }}</p>
          <p>{{ item.span.slice(9) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      img: require('../../assets/Web/about/img.png'),
      topBg: require('../../assets/Web/about/topBg.png'),
      team1: require('../../assets/Web/about/team1.png'),
      team2: require('../../assets/Web/about/team2.png'),
      team3: require('../../assets/Web/about/team3.png'),
      center1: require('../../assets/Web/about/1.png'),
      center2: require('../../assets/Web/about/2.png'),
      center3: require('../../assets/Web/about/3.png'),
      centerList: [
        {
          img: require('../../assets/Web/about/1.png')
          , title: '愿景', span: '成为最优秀的互联网定制品牌'
        },
        {
          img: require('../../assets/Web/about/2.png')
          , title: '使命', span: '以专业的技术实现产品落地'
        }, {
          img: require('../../assets/Web/about/3.png')
          , title: '价值观', span: '服务至上，创新合作，诚信立业'
        },
      ],
      culture: require('../../assets/Web/about/culture.png'),
      teamList: [
        {
          img: require('../../assets/Web/about/team1.png')
          , title: '专业技术团队', span: '经验丰富且拥有较强开发能力'
        },
        {
          img: require('../../assets/Web/about/team2.png')
          , title: '创新设计团队', span: '保证独一无二的创新设计风格'
        }, {
          img: require('../../assets/Web/about/team3.png')
          , title: '实时运维团队', span: '时刻跟踪维护已上线程序'
        },
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.about {
  .aboutTop {
    width: 100%;
    height: 453PX;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    img {
      height: 453PX;
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
    .text {
      p:first-child {
        font-weight: bold;
        font-size: 45.56PX;
        color: #fff;
        letter-spacing: 4PX;
      }
    }
  }
  .about-center {
    margin-top: 74PX;
    display: flex;
    flex-direction: column;
    align-items: center;
    .about-center-top {
      margin-bottom: 35PX;
      p {
        color: #000000;
        font-size: 38PX;
        font-weight: bold;
      }
    }
    .about-center-center {
      width: 929.1PX;
      height: 371PX;
      background-repeat: no-repeat;
      background-size: cover;
      padding-left: 33PX;
      box-sizing: border-box;
      .about-center-center-top {
        margin-top: 20PX;
        p {
          color: #000000;
          font-size: 26PX;
          font-family: SourceHanSansCN-Medium;
        }
      }
      .about-center-center-bottom {
        width: 480PX;
        word-wrap: break-word;
        p {
          text-indent: 32PX;
          font-size: 16PX;
          color: #666666;
          line-height: 26PX;
          margin-bottom: 13PX;
        }
      }
      .about-center-center-line {
        margin-top: 8PX;
        margin-bottom: 13PX;
        width: 104PX;
        height: 2PX;
        background-color: #999999;
        border-radius: 0.71PX;
      }
    }
    .about-center-bottom {
      display: flex;
      div {
        text-align: center;
        margin-right: 76PX;
        p:nth-child(2) {
          font-size: 20PX;
          color: #000000;
          letter-spacing: 1PX;
          font-family: SourceHanSansCN-Medium;
          margin-top: 5PX;
          margin-bottom: 5PX;
        }
        p:nth-child(3) {
          font-size: 16PX;
          color: #666666;
          letter-spacing: 1PX;
        }
      }

      div:nth-child(1) {
        margin-top: 42PX;
        img {
          width: 76PX;
          height: 54PX;
        }
      }
      div:nth-child(2) {
        margin-top: 33PX;
        img {
          width: 60PX;
          height: 62PX;
        }
      }
      div:nth-child(3) {
        margin-top: 46PX;
        margin-right: 0PX;

        img {
          width: 63PX;
          height: 49PX;
        }
      }
    }
  }

  .about-culture {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 136PX;

    .about-culture-left {
      width: 606PX;
      height: 575PX;
      margin-right: 120PX;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .about-culture-right {
      // margin-left: 200PX;
      .title {
        font-size: 50PX;
        color: #000000;
        font-family: SourceHanSansCN-Bold;
        margin-bottom: 20PX;
      }

      p {
        font-size: 24PX;
        color: #8a8a8a;
        line-height: 38PX;
        font-family: SourceHanSansCN-Regular;
      }
    }
  }

  .about-bottom {
    margin-bottom: 100PX;
    margin-top: 63PX;
    display: flex;
    flex-direction: column;
    align-items: center;
    .about-bottom-top {
      margin-bottom: 58PX;
      p {
        color: #000000;
        font-size: 38PX;
        font-weight: bold;
      }
    }
    .about-bottom-bottom {
      display: flex;
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
      }
      div{
        width: 282.2PX;
        position: relative;
        margin-right: 37PX;

        p:nth-child(2) {
          font-size: 20PX;
          color: #000000;
          font-family: SourceHanSansCN-Medium;
          margin-bottom: 5PX;
        }
        p{
          margin-left: 33PX;
          font-size: 14PX;
          color: #999999;
        }
      }
      div:nth-child(1) {
        height: 174.7PX;
        p:nth-child(2){
        margin-top: 81PX;
      }
      }
      div:nth-child(2) {
        height: 209PX;
        margin-top: -35PX;
        p:nth-child(2){
        margin-top: 116PX;
      }
      }
      div:nth-child(3) {
        margin-top: -19PX;
        height: 193PX;
        margin-right: 0PX;
        p:nth-child(2){
        margin-top: 100PX;
      }
      }
    }
  }
}
</style>