var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('div',{staticClass:"aboutTop"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.topBg),expression:"topBg"}],attrs:{"alt":"","srcset":""}}),_vm._m(0)]),_c('div',{staticClass:"about-center"},[_vm._m(1),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:backgroundImage",value:(_vm.img),expression:"img",arg:"backgroundImage"}],staticClass:"about-center-center"},[_vm._m(2),_c('div',{staticClass:"about-center-center-line"}),_vm._m(3)]),_c('div',{staticClass:"about-center-bottom"},_vm._l((_vm.centerList),function(item,index){return _c('div',{key:index},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.img),expression:"item.img"}],attrs:{"alt":""}}),_c('p',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.span))])])}),0)]),_c('div',{staticClass:"about-culture"},[_c('div',{staticClass:"about-culture-left"},[_c('img',{attrs:{"src":_vm.culture,"alt":""}})]),_vm._m(4)]),_c('div',{staticClass:"about-bottom"},[_vm._m(5),_c('div',{staticClass:"about-bottom-bottom"},_vm._l((_vm.teamList),function(item,index){return _c('div',{key:index},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.img),expression:"item.img"}],attrs:{"alt":""}}),_c('p',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.span.slice(0,9)))]),_c('p',[_vm._v(_vm._s(item.span.slice(9)))])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("千述信息科技（厦门）有限公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-center-top"},[_c('p',[_vm._v("公司简介")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-center-center-top"},[_c('p',[_vm._v("关于我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-center-center-bottom"},[_c('p',[_vm._v("千述信息科技(厦门)有限公司是一家为各行各业提供解决方案的公司，利用移动互联、大数据、人工智能和物联网等先进技术，为众多企业提供系统开发、技术咨询及大数据运营等服务。")]),_c('p',[_vm._v("公司组建了各环节的专项团队，从产品开发落地到上线运营到售后服务，各团队分工明确，执行高效，致力为客户提供最全面舒心的服务。除解决方案外，公司自主研发设计了商城平台及区块链系统等产品，发挥独创的多场景、定制化、全方位、精准触达的核心优势，赋能企业营销快速裂变。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-culture-right"},[_c('div',{staticClass:"title"},[_vm._v("企业文化")]),_c('p',[_vm._v("千述科技以打造最具指向性的定制服务")]),_c('p',[_vm._v("为宗旨，以成就客户、服务至上、创新")]),_c('p',[_vm._v("合作、激情奋斗、诚信立业的价值观为")]),_c('p',[_vm._v("基石，以专业技术帮助客户实现品牌落")]),_c('p',[_vm._v("地，努力奋斗成为最优秀的互联网定制")]),_c('p',[_vm._v("品牌。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-bottom-top"},[_c('p',[_vm._v("团队构成")])])
}]

export { render, staticRenderFns }